import { API } from 'aws-amplify';
import { getLinks } from '../../graphql/queries';
import { createLink, deleteLink } from '../../graphql/mutations';

export const fetchWebLinks = async (familyId) => {
    const { data, errors } = await API.graphql({
        query: getLinks,
        variables: { familyId: familyId }
    });
    if( errors ) {
        return new Error( errors[0].message );
    }
    return data;
}

export const createNewLink = async (familyId, link, description) => {
    const { data: { createLink: details }, errors } = await API.graphql({
        query: createLink,
        variables: { familyId: familyId, link: link, description: description }
    });
    if( errors ) {
        return new Error( errors[0].message );
    }
    return details;
}

export const deleteLinkById = async (familyId, linkId) => {
    const { data: { deleteLink: details }, errors } = await API.graphql({
        query: deleteLink,
        variables: { familyId: familyId, linkId: linkId }
    });
    if( errors ) {
        return new Error( errors[0].message );
    }
    return details;
}